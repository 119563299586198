import { T } from '@transifex/react';
import ClearIcon from 'assets/icons/clear_black.svg';
import ProfileIcon from 'assets/icons/profile.svg';
import WeAre8Logo from 'assets/icons/weare8.svg';
import { Title } from 'assets/styles/theme';
import Button from 'components/Button/Button';
import { User } from 'next-auth';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useDebounce } from 'react-use';
import { StyledHeaderContainer } from './Header.styled';
import { DEFAULT_PROFILE_IMAGE } from 'constants/user';

interface HeaderProps {
  profile: User;
  logout: () => void;
}

const Header: React.FC<HeaderProps> = ({ profile, logout }) => {
  const router = useRouter();
  const [showMenu, setShowMenu] = useState(false);
  const [debouncedShowMenu, setDebouncedShowMenu] = useState(false);
  useDebounce(
    () => {
      setDebouncedShowMenu(showMenu);
    },
    500,
    [showMenu]
  );

  return (
    <StyledHeaderContainer>
      <div className="left-side">
        {router.pathname.includes('posts') && (
          <button onClick={() => router.back()} className="back-to-profile">
            <ClearIcon />
          </button>
        )}
        <WeAre8Logo />
      </div>
      <div className="right-side">
        <Button
          data-testid="header.btnUpload"
          disabled={router.pathname.includes('upload')}
          styleType="primary"
          size="small"
          as="Link"
          href="/upload"
        >
          <T _str="Upload" />
        </Button>

        <div
          className="profile-image-container"
          onMouseEnter={() => {
            setDebouncedShowMenu(true);
            setShowMenu(true);
          }}
          onMouseLeave={() => setShowMenu(false)}
        >
          <Image
            src={profile?.profilePictureUri ?? DEFAULT_PROFILE_IMAGE}
            alt="profile image"
            width={32}
            height={32}
          />
          <div
            className="dropdown"
            style={{
              opacity: debouncedShowMenu ? 1 : 0,
              visibility: debouncedShowMenu ? 'visible' : 'hidden',
            }}
          >
            <ul>
              <li>
                <Link data-testid="header.btnProfile" href={`/@${profile?.username}`}>
                  <ProfileIcon />
                  <Title>
                    <T _str="View Profile" />
                  </Title>
                </Link>
              </li>
              <li>
                <button data-testid="header.btnLogout" onClick={logout}>
                  <Title>
                    <T _str="Logout" />
                  </Title>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </StyledHeaderContainer>
  );
};
export default Header;
