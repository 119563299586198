import Header from 'components/Header/Header';
import Loader from 'components/Loader/Loader';
import Toast from 'components/Toast';
import useAuthentication from 'hooks/useAuthentication';
import { signOut } from 'next-auth/react';
import { useRouter } from 'next/router';
import React, { HTMLAttributes, ReactNode, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.min.css';

import styled from 'styled-components';

const StyledLayoutContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;

  .inner-page-layout-container {
    width: 100%;
    max-width: 500px;
    padding: 16px;
    background-color: ${({ theme }) => theme.palette.white};
    min-height: 100vh;
    height: 100vh;
    min-height: 100dvh;
    height: 100dvh;

    &.no-padding {
      padding: 0;
    }

    &.full-width {
      max-width: 100%;
    }

    &.with-extra-padding-top {
      padding-top: 52px;
      min-height: calc(100vh - 52px);
      min-height: calc(100dvh - 52px);
    }
  }
`;

interface PageLayoutProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode | ReactNode[];
  withoutPadding?: boolean;
  onScroll?: () => void;
  fullWidth?: boolean;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  withoutPadding,
  onScroll,
  children,
  fullWidth,
  ...rest
}) => {
  useEffect(() => {
    if (onScroll) {
      document.addEventListener('scroll', onScroll);
      return () => document.removeEventListener('scroll', onScroll);
    }
  }, [onScroll]);

  const router = useRouter();

  const { isUserAuthenticated, user: loggedInUser, isLoading: isAuthLoading } = useAuthentication();

  return (
    <>
      {loggedInUser && !router.pathname.includes('login') && (
        <Header profile={loggedInUser} logout={() => signOut({ callbackUrl: '/login' })} />
      )}

      {isAuthLoading && <Loader />}

      <StyledLayoutContainer>
        <div
          className={`inner-page-layout-container ${fullWidth ? 'full-width' : ''} ${
            withoutPadding ? 'no-padding' : ''
          } ${isUserAuthenticated ? 'with-extra-padding-top' : ''}`}
          {...rest}
        >
          {children}
          <Toast />
        </div>
      </StyledLayoutContainer>
    </>
  );
};

export default PageLayout;
