import styled from 'styled-components';

export const StyledHeaderContainer = styled.nav`
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 32px;
  height: 52px;
  background-color: ${({ theme }) => theme.palette.white};
  box-shadow: 0 4px 2px -2px ${({ theme }) => theme.palette.black[200]};

  .left-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    .back-to-profile {
      border-radius: 100%;
      background-color: ${({ theme }) => theme.palette.black[200]};
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .right-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    .profile-image-container {
      position: relative;
      width: 32px;
      height: 32px;
      img {
        border-radius: 100%;
        cursor: pointer;
      }

      .dropdown {
        position: absolute;
        transition: opacity 0.5s;
        background-color: ${({ theme }) => theme.palette.white};
        border-radius: 8px;
        width: 200px;
        top: 45px;
        right: -16px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

        &::before {
          content: '';
          position: absolute;
          height: 0px;
          width: 0px;
          right: 23px;
          top: -15px; /* 1px buffer for zooming problems while rendering*/
          border-width: 8px;
          border-color: transparent transparent ${({ theme }) => theme.palette.white} transparent;
          border-style: solid;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }

        ul {
          margin: 0;
          padding: 0;
          li {
            list-style: none;
            padding: 12px 16px;
            &:last-child {
              border-top: 1px solid ${({ theme }) => theme.palette.black[200]};
            }

            a {
              display: flex;
              gap: 8px;
              align-items: center;
            }
          }
        }
      }
    }
  }
`;
