import React from 'react';

import ButtonLoaderIcon from 'assets/icons/loader.svg';
import { LoadingButtonContainer } from './ButtonLoader.styled';

export interface ButtonLoaderProps {
  black?: boolean;
}

const ButtonLoader: React.FC<ButtonLoaderProps> = ({ black = false }) => {
  return (
    <LoadingButtonContainer black={black}>
      <ButtonLoaderIcon />
    </LoadingButtonContainer>
  );
};

export default ButtonLoader;
