import React from 'react';
import { StyledLoaderContainer } from './Loader.styled';
import Image from 'next/image';

const Loader = () => {
  return (
    <StyledLoaderContainer>
      <div className="animating">
        <Image src="/weAre8logo.png" alt="WeAre8 Logo" width={128} height={128} priority />
        <Image src="/weAre8.png" alt="WeAre8" width={160} height={35} priority />
      </div>
    </StyledLoaderContainer>
  );
};

export default Loader;
