import styled from 'styled-components';
import { ButtonLoaderProps } from './ButtonLoader';

export const LoadingButtonContainer = styled.div<ButtonLoaderProps>`
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${({ theme, black }) => (black ? theme.palette.black[900] : theme.palette.white)};
    width: 100%;
    height: 100%;
  }
`;
