import { ReactNode } from 'react';
import { StyledToast } from './Toast.styled';
import { ToastOptions, toast as toastFunc } from 'react-toastify';

import SvgSuccessIcon from 'assets/icons/success.svg';
import SvgErrorIcon from 'assets/icons/error.svg';

const Toast = () => {
  return (
    <StyledToast
      position="top-center"
      autoClose={7000}
      hideProgressBar={true}
      newestOnTop={true}
      rtl={false}
      closeButton={false}
      closeOnClick={false}
      icon={false}
      limit={1}
      pauseOnFocusLoss={false}
    />
  );
};
export default Toast;

// eslint-disable-next-line no-unused-vars
const { success, error, ...rest } = toastFunc;

export const toast = {
  success: (textToRender: string | ReactNode, options?: ToastOptions) =>
    toastFunc.success(
      <>
        <div className="icon-container">
          <SvgSuccessIcon />
        </div>
        <div className="text-container">{textToRender}</div>
      </>,
      { ...options }
    ),
  error: (textToRender: string | ReactNode, options?: ToastOptions) =>
    toastFunc.error(
      <>
        <div className="icon-container">
          <SvgErrorIcon />
        </div>
        <div className="text-container">{textToRender}</div>
      </>,
      { ...options }
    ),
  ...rest,
};
