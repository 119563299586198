import { useSession } from 'next-auth/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAccessToken,
  selectMachineToken,
  selectRefreshToken,
  setAccessToken,
  setMachineToken,
  setRefreshToken,
} from 'store/state/authSlice';

const useAuthentication = () => {
  const { data: session, status } = useSession();
  const dispatch = useDispatch();
  const storedAccessToken = useSelector(selectAccessToken);
  const storedMachineToken = useSelector(selectMachineToken);
  const storedRefreshToken = useSelector(selectRefreshToken);

  useEffect(() => {
    if (session?.user) {
      dispatch(setMachineToken(session.user.machineToken));
      dispatch(setAccessToken(session.user.accessToken));
      dispatch(setRefreshToken(session.user.refreshToken));
    }
  }, [dispatch, session?.user]);

  return {
    isLoading:
      status === 'loading' && (!storedAccessToken || !storedMachineToken || !storedRefreshToken),
    isUserAuthenticated:
      Boolean(session?.user) ||
      (status !== 'loading' &&
        Boolean(storedAccessToken) &&
        Boolean(storedMachineToken) &&
        Boolean(storedRefreshToken)),
    user: session?.user,
    isSignUpCompleted: session?.user?.isSignUpCompleted,
  };
};

export default useAuthentication;
